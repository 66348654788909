import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Store} from '@ngxs/store';
import {combineLatest, Observable} from 'rxjs';
import {debounceTime, startWith, switchMap, takeUntil} from 'rxjs/operators';

import {PatientModel, PatientStatus} from '@matchsource/models/patient';
import {DuplicatesModel} from 'app/shared';
import {BusinessPartyTypes, TCS_WITH_INTERNATIONAL_BEHAVIOUR} from '@matchsource/models/business-party';
import {PatientProcess} from 'app/features/patient/constants';
import {BusinessPartiesService} from '@matchsource/store/business-parties';
import {initFormControlValueChanges} from '@matchsource/form';
import {CHECK_DUPLICATE_DEBOUNCE} from 'app/features/patient-form/constants';
import {CheckPatientDuplicate, ResetPatientDuplicate} from '../store/patient-form.actions';
import {UserService} from '@matchsource/core';

@Injectable({
  providedIn: 'root',
})
export class DuplicateService {
  constructor(
    private readonly user: UserService,
    private readonly bp: BusinessPartiesService,
    private readonly store: Store
  ) {}

  checkForUserRoles(): boolean {
    return this.user.isCoreUser() || this.user.isPE();
  }

  checkHLATodayPatientApplicableForDuplicatesSearch(data: DuplicatesModel): boolean {
    const isPatientProcessHLAToday = data.patient?.patientProcess === PatientProcess.HlaToday;
    const isBPCP = data.businessParty?.type === BusinessPartyTypes.CommunityPractice;
    const isTC500 = data.businessParty?.identifier === TCS_WITH_INTERNATIONAL_BEHAVIOUR.HLA_TODAY_TC;
    const isBPTC500OrCP = isTC500 || isBPCP;
    const isUserCPOrCPE = this.user.isCP() || this.user.isCPE();
    const isUserCMOrCHS = this.user.isCM() || this.user.isCHS();
    const isLocalIdEnterd = !!data.patient.localId;

    if (isUserCPOrCPE && isBPTC500OrCP && isPatientProcessHLAToday) {
      return true;
    }

    if (isUserCMOrCHS && isBPTC500OrCP && isPatientProcessHLAToday) {
      return true;
    }

    if (isLocalIdEnterd && isPatientProcessHLAToday && this.checkForUserRoles()) {
      return true;
    }

    return false;
  }

  checkRefIdApplicableForDuplicateSearch(data: DuplicatesModel): boolean {
    const isRefIdEntered = !!data.patient.refId;

    return isRefIdEntered && this.checkForUserRoles();
  }

  checkApplicableForDuplicatesSearch(data: DuplicatesModel): boolean {
    if (!this.isPatientApplicableForDuplicates(data.patient)) {
      return false;
    }
    if (this.checkHLATodayPatientApplicableForDuplicatesSearch(data)) {
      return true;
    }
    if (this.checkRefIdApplicableForDuplicateSearch(data)) {
      return true;
    }
    const isHLATodayProcess = (data.patient || {patientProcess: false}).patientProcess === PatientProcess.HlaToday;
    const isHlaToday = (data.businessParty || {patientProcess: false}).patientProcess;
    return this.isTCApplicableForDuplicates(data) && !(isHlaToday && !isHLATodayProcess) && this.checkForUserRoles();
  }

  checkForDuplicates(
    patient$: Observable<PatientModel>,
    tcId$: Observable<string>,
    isDomesticSecondaryTc$: Observable<boolean>,
    refId$: Observable<string>,
    localId$: Observable<string>,
    form: UntypedFormGroup,
    takeUntilObservable$: Observable<void>
  ): void {
    combineLatest([
      patient$,
      tcId$.pipe(switchMap(tcId => this.bp.getTcById(tcId))),
      isDomesticSecondaryTc$.pipe(startWith(false)),
      refId$.pipe(startWith(null)),
      localId$.pipe(startWith(null)),
      form.controls.firstName.valueChanges,
      form.controls.lastName.valueChanges,
      initFormControlValueChanges(form.controls.birthDate),
      initFormControlValueChanges(form.controls.patientProcess),
    ])
      .pipe(debounceTime(CHECK_DUPLICATE_DEBOUNCE), takeUntil(takeUntilObservable$))
      .subscribe(([patient, businessParty, domesticSecondaryTc]) => {
        const patientData = {...patient, ...form.value};
        const duplicateData: DuplicatesModel = {patient: patientData, businessParty, domesticSecondaryTc};
        const applicable = this.checkApplicableForDuplicatesSearch(duplicateData);

        if (!applicable) {
          this.store.dispatch(new ResetPatientDuplicate());
          return;
        }

        this.store.dispatch(new CheckPatientDuplicate(patientData));
      });
  }

  private isPatientApplicableForDuplicates(patient: PatientModel): boolean {
    if (!patient) {
      return false;
    }

    if (
      !!patient.status &&
      patient.status !== PatientStatus.PreliminarySearch &&
      patient.status !== PatientStatus.InProgress
    ) {
      return false;
    }

    if (!patient.firstName || !patient.lastName || !patient.birthDate) {
      return false;
    }

    return true;
  }

  private isTCApplicableForDuplicates(data: DuplicatesModel): boolean {
    const identifier = (data.businessParty || {identifier: null}).identifier;
    const domestic = (data.businessParty || {domestic: undefined}).domestic;
    if (!data.businessParty || identifier === TCS_WITH_INTERNATIONAL_BEHAVIOUR.FOR_NBD_USERS) {
      return false;
    }
    if (identifier === TCS_WITH_INTERNATIONAL_BEHAVIOUR.NON_NETWORK_TC) {
      return data.domesticSecondaryTc;
    }
    return identifier === TCS_WITH_INTERNATIONAL_BEHAVIOUR.HLA_TODAY_TC || domestic;
  }
}
