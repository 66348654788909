import {AbstractControl, ValidationErrors} from '@angular/forms';
import {isEmpty} from '@matchsource/utils';

export const MIN_TEN_VALIDATOR_KEY = 'min_ten';

export const minTenValidator = (control: AbstractControl): ValidationErrors | null => {
  const {value} = control;
  const isValid = isEmpty(value) || value.toString().length >= 10;

  return !isValid ? {[MIN_TEN_VALIDATOR_KEY]: {value: control.value}} : null;
};
