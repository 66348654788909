import {State, Action, StateContext, Selector} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {tap, catchError} from 'rxjs/operators';
import {KitOrderApiService} from '@matchsource/api/orders';
import {ClearLabRefs, GetLabRefs} from './kit-order-lab-refs.actions';
import {Observable, of} from 'rxjs';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  selectEntities,
  setError,
} from '@matchsource/store/core';
import {compose, patch} from '@ngxs/store/operators';
import {LabRefferenceIdDropDownItem} from '@matchsource/models/order';

export type KitOrderLabRefsStateModel = EntitiesStateModel<LabRefferenceIdDropDownItem>;

@State<KitOrderLabRefsStateModel>({
  name: 'kitOrderLabRefsStateStateModel',
  defaults: defaultEntitiesState<LabRefferenceIdDropDownItem>(),
})
@Injectable()
export class KitOrderLabRefsStateState {
  constructor(private readonly kitOrderApiService: KitOrderApiService) {}

  @Selector([KitOrderLabRefsStateState])
  static list(state: KitOrderLabRefsStateModel) {
    return selectEntities<LabRefferenceIdDropDownItem>(state);
  }

  @Selector([KitOrderLabRefsStateState])
  static loaded(state: KitOrderLabRefsStateModel) {
    return state.loaded;
  }

  @Action(GetLabRefs)
  getLabRefs(
    ctx: StateContext<KitOrderLabRefsStateModel>,
    {bpGuid}: GetLabRefs
  ): Observable<LabRefferenceIdDropDownItem[]> {
    ctx.setState(
      compose(patch(defaultEntitiesState<LabRefferenceIdDropDownItem>()), loadingEntities(true), loadedEntities(false))
    );

    return this.kitOrderApiService.getAllLabKitRefs(bpGuid).pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(kitRefs =>
        ctx.setState(
          compose(
            addOrReplaceEntities<LabRefferenceIdDropDownItem>('labRefId', kitRefs),
            loadedEntities(true),
            loadingEntities(false)
          )
        )
      )
    );
  }

  @Action(ClearLabRefs)
  clearLabRefs(ctx: StateContext<KitOrderLabRefsStateModel>) {
    ctx.patchState(defaultEntitiesState<LabRefferenceIdDropDownItem>());
  }
}
