/* eslint-disable */
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {catchError, finalize, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {PatientSaveWithTraceModel} from '@matchsource/api/patient';
import {PatientHlaLocusModel, PatientHlaLocusValidityModel, PatientModel} from '@matchsource/models/patient';
import {PatientApiService} from '@matchsource/api/patient';
import {
  ClearPatient,
  SavePatient,
  GetHlaValidity,
  LoadPatient,
  CheckPatientDuplicate,
  ResolvePatientDuplicates,
  ResetPatientDuplicate,
} from 'app/features/patient-root/store/patient-form.actions';
import {UsersApiService} from '@matchsource/api/users';
import {UserModel} from '@matchsource/models/user';
import {isEmpty} from '@matchsource/utils';
import {hlaSerializer} from '@matchsource/api/hla';
import cloneDeep from 'lodash-es/cloneDeep';
import {firstValueFrom, of} from 'rxjs';
import {HlaHistoryLocusModel} from '@matchsource/models/hla';
import {LociService} from '@matchsource/store/loci';

export interface PatientStateModel {
  loading: boolean;
  loaded: boolean;
  patient: PatientModel;
  createdBy: UserModel;
  preferredTestResult: MsApp.Dictionary<HlaHistoryLocusModel>;
  hlaValidity: PatientHlaLocusValidityModel[];
  lastResponse: PatientSaveWithTraceModel | null;
  isFrmlClosedDuplicate: boolean;
  isPrlmDuplicate: boolean;
  isHLATodayDuplicate: boolean;
  isLocalIdDuplicate: boolean;
  isRedIdDuplicate: boolean;
}

const defaultPatient = (): PatientStateModel => ({
  loading: false,
  loaded: false,
  patient: null,
  createdBy: null,
  preferredTestResult: null,
  hlaValidity: null,
  lastResponse: null,
  isFrmlClosedDuplicate: false,
  isPrlmDuplicate: false,
  isHLATodayDuplicate: false,
  isLocalIdDuplicate: false,
  isRedIdDuplicate: false,
});

@State<PatientStateModel>({
  name: 'patientForm',
  defaults: defaultPatient(),
})
@Injectable()
export class PatientFormState {
  constructor(
    private readonly api: PatientApiService,
    private readonly users: UsersApiService,
    private readonly lociService: LociService
  ) {}

  @Selector([PatientFormState])
  static patientForm(state: PatientStateModel): PatientModel {
    return state.patient;
  }

  @Selector([PatientFormState])
  static hlaValidity(state: PatientStateModel): PatientHlaLocusValidityModel[] {
    return state.hlaValidity;
  }

  @Selector([PatientFormState])
  static createdBy(state: PatientStateModel): UserModel {
    return state.createdBy;
  }

  @Selector([PatientFormState])
  static loading(state: PatientStateModel): boolean {
    return state.loading;
  }

  @Selector([PatientFormState])
  static loaded({loaded}: PatientStateModel): boolean {
    return loaded;
  }

  @Selector([PatientFormState])
  static lastResponse(state: PatientStateModel): PatientSaveWithTraceModel | null {
    return state.lastResponse;
  }

  @Selector([PatientFormState])
  static isFrmlClosedDuplicate(state: PatientStateModel): boolean {
    return state.isFrmlClosedDuplicate;
  }

  @Selector([PatientFormState])
  static isPrlmDuplicate(state: PatientStateModel): boolean {
    return state.isPrlmDuplicate;
  }

  @Selector([PatientFormState])
  static isHLATodayDuplicate(state: PatientStateModel): boolean {
    return state.isHLATodayDuplicate;
  }

  @Selector([PatientFormState])
  static isLocalIdDuplicate(state: PatientStateModel): boolean {
    return state.isLocalIdDuplicate;
  }

  @Selector([PatientFormState])
  static isRedIdDuplicate(state: PatientStateModel): boolean {
    return state.isRedIdDuplicate;
  }

  @Selector([PatientFormState])
  static combinedLoci(state: PatientStateModel): PatientHlaLocusModel[] | null {
    if (
      !state.patient ||
      !state.patient.phenotype ||
      !state.patient.phenotype.loci ||
      state.patient.phenotype.loci.length === 0
    ) {
      return null;
    }

    const resultPatientHlaLocusArray: PatientHlaLocusModel[] = cloneDeep(state.patient.phenotype.loci);
    resultPatientHlaLocusArray.forEach(patientHlaLocus => {
      if (
        patientHlaLocus.glstringInd &&
        !!state.preferredTestResult &&
        state.preferredTestResult[patientHlaLocus.name]
      ) {
        patientHlaLocus.type1 = state.preferredTestResult[patientHlaLocus.name].type1;
        patientHlaLocus.type2 = state.preferredTestResult[patientHlaLocus.name].type2;
        patientHlaLocus.type1Formatted = state.preferredTestResult[patientHlaLocus.name].type1Formatted;
        patientHlaLocus.type2Formatted = state.preferredTestResult[patientHlaLocus.name].type2Formatted;
      }
    });

    return resultPatientHlaLocusArray;
  }

  @Action(SavePatient)
  save(ctx: StateContext<PatientStateModel>, {patient}: SavePatient) {
    ctx.patchState({
      loading: true,
      lastResponse: null,
    });

    return this.api.save(patient).pipe(
      tap(result => {
        ctx.patchState({
          lastResponse: result,
        });
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(GetHlaValidity)
  hlaValidity(ctx: StateContext<PatientStateModel>, {hlaLocus}: GetHlaValidity) {
    ctx.patchState({
      hlaValidity: null,
      loading: true,
    });
    const toValidateHlaLocus = hlaLocus.filter(item => !isEmpty(item.type1) || !isEmpty(item.type2));

    return this.api.getHlaValidity(toValidateHlaLocus).pipe(
      catchError(() => of(null)),
      tap(hlaValidity => {
        ctx.patchState({
          hlaValidity,
          loading: false,
          loaded: true,
        });
      })
    );
  }

  @Action(LoadPatient)
  async load(ctx: StateContext<PatientStateModel>, {id, index}: LoadPatient) {
    ctx.patchState({
      loading: true,
    });

    try {
      const [patient, loci, preferredTestResult] = await Promise.all([
        firstValueFrom(this.api.get(id)),
        firstValueFrom(this.lociService.loci$),
        firstValueFrom(this.api.getPTR(id, index)),
      ]);
      const createdBy = await firstValueFrom(this.users.get(patient.createdById));

      patient.phenotype.loci = loci.map(name => {
        const patientLocus = patient.phenotype.loci.find(locus => locus.name === name);
        return hlaSerializer.createHlaModel(patientLocus || {name});
      });

      ctx.patchState({
        patient,
        preferredTestResult,
        createdBy,
        loaded: true,
      });
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }

  @Action(ResetPatientDuplicate)
  resetDuplicate(ctx: StateContext<PatientStateModel>) {
    ctx.patchState({
      isFrmlClosedDuplicate: false,
      isPrlmDuplicate: false,
      isHLATodayDuplicate: false,
      isLocalIdDuplicate: false,
      isRedIdDuplicate: false,
    });
  }

  @Action(CheckPatientDuplicate)
  checkDuplicate(ctx: StateContext<PatientStateModel>, {patient}: CheckPatientDuplicate) {
    return this.api.checkDuplicate(patient).pipe(
      tap(result => {
        ctx.patchState({
          isFrmlClosedDuplicate: result.duplicatesAmongFrmlOrClosed > 0,
          isPrlmDuplicate: result.duplicatesAmongPrlm > 0,
          isHLATodayDuplicate: result.duplicatesAmongHla > 0,
          isLocalIdDuplicate: result.duplicatesByLocalId > 0,
          isRedIdDuplicate: result.duplicatesByRefId > 0,
        });
      })
    );
  }

  @Action(ResolvePatientDuplicates)
  resolveDuplicates(ctx: StateContext<PatientStateModel>, {id}: ResolvePatientDuplicates) {
    ctx.patchState({
      loading: true,
    });

    return this.api.resolveDuplicates(id).pipe(finalize(() => ctx.patchState({loading: false})));
  }

  @Action(ClearPatient)
  clear(ctx: StateContext<PatientStateModel>) {
    ctx.setState(defaultPatient());
  }
}
