import {AbstractControl, ValidationErrors} from '@angular/forms';
import {isEmpty} from '@matchsource/utils';

export const MIN_FIVE_VALIDATOR_KEY = 'min_five';

export const minFiveValidator = (control: AbstractControl): ValidationErrors | null => {
  const {value} = control;
  const isValid = isEmpty(value) || value.toString().length >= 5;

  return !isValid ? {[MIN_FIVE_VALIDATOR_KEY]: {value: control.value}} : null;
};
