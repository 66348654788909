import {Serializer} from '@matchsource/api-utils';
import {UserModel} from '@matchsource/models/user';
import {CaseResourceDto} from '@matchsource/api-generated/common';

export const userSerializer: Serializer<UserModel, CaseResourceDto> = {
  fromDTO(input: CaseResourceDto): UserModel {
    if (!input) {
      return null;
    }

    return {
      fullName: `${input.lastName}, ${input.firstName}`,
    };
  },
};
