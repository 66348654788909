import {Serializer} from '@matchsource/api-utils';
import {InventorySerialNumberDetails} from '@matchsource/api-generated/orders';
import {LabRefferenceIdDropDownItem} from '@matchsource/models/order';

export const kitOrderSerializer: Serializer<LabRefferenceIdDropDownItem, InventorySerialNumberDetails> = {
  fromDTO(input: InventorySerialNumberDetails): LabRefferenceIdDropDownItem {
    return {
      labRefId: input.serialNumber,
      labRefLabel: input.serialNumber,
      traceCode: `${input.returnTrackingNumber ? input.returnTrackingNumber : ''}`,
    };
  },
};
