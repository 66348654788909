import {PatientUniqueDataSet, PatientModel, PatientHlaLocusModel} from '@matchsource/models/patient';

import {ACTUAL_PHENOTYPE_INDEX} from '@matchsource/models/patient-shared';

export class LoadPatient {
  static readonly type = '[PatientForm] Load Patient';

  constructor(
    public readonly id: MsApp.Guid,
    public readonly index: number = ACTUAL_PHENOTYPE_INDEX
  ) {}
}

export class SavePatient {
  static readonly type = '[PatientForm] Save';

  constructor(public readonly patient: PatientModel) {}
}

export class ClearPatient {
  static readonly type = '[PatientForm] Clear';
}

export class GetHlaValidity {
  static readonly type = '[PatientForm] Hla Validity';

  constructor(public readonly hlaLocus: PatientHlaLocusModel[]) {}
}

export class CheckPatientDuplicate {
  static readonly type = '[PatientForm] Check Duplicate';

  constructor(public readonly patient: PatientUniqueDataSet) {}
}

export class ResetPatientDuplicate {
  static readonly type = '[PatientForm] Reset Duplicate';
}

export class ResolvePatientDuplicates {
  static readonly type = '[PatientForm] Resolve Duplicates';

  constructor(public readonly id: MsApp.Guid) {}
}
