import {isEmpty} from '@matchsource/utils';
import {PatientHlaLocusModel} from '@matchsource/models/patient';

export const SHIPPING_ADDRESS_GROUP_NAME = 'altShippingAddress';

export const OTHER_DIAGNOSIS_CODES = ['OMS', 'OOL', 'OND'];

export const NOT_APPLICABLE = 'NA';

export const TRANSPLANT_TIMELINES = {
  PEND: 'PEND',
};

export const getCleanLoci = (loci: PatientHlaLocusModel[]) =>
  loci.map(locus => ({...locus, type2: !isEmpty(locus.type1) ? locus.type2 : ''}));

export const enum FormMode {
  Create = 'create',

  Edit = 'edit',
}

export enum PhenotypeFormMode {
  AddActual = 'addActual',

  AddAlternate = 'addAlternate',

  Edit = 'edit',
}

export const DEFAULT_DISEASE_STAGE = 'IT';

export const DEFAULT_REMISSIONS_COUNT = 0;

export enum MyPatientTabPaths {
  PatientInDetails = 'patient.abstract-view.view',
  WaitingForTyping = 'my-patients.patients-waiting-typing',
  TypingReceived = 'my-patients.patients-typing-received',
}

export const CHECK_DUPLICATE_DEBOUNCE = 500;
