import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  AddressValidationControllerService,
  AddressAutocompleteRequest,
  AddressBase,
} from '@matchsource/api-generated/common';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {SmartyAddressWithSecondaryEntries, ValidationSmartAddressResult} from '@matchsource/models/smarty-street';

@Injectable({
  providedIn: 'root',
})
export class AddressApiService {
  constructor(private readonly addressApi: AddressValidationControllerService) {}

  getAddressSuggestions(request: AddressAutocompleteRequest): Observable<SmartyAddressWithSecondaryEntries[]> {
    const httpContext = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingAddressSuggestions)();
    return this.addressApi.autocompleteUsAddress({
      context: httpContext,
      body: request,
    });
  }

  validateUsAddress(address: AddressBase): Observable<ValidationSmartAddressResult> {
    const httpContext = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.ValidatingAddress)();
    return this.addressApi.validateUsAddress({
      context: httpContext,
      body: address,
    });
  }
}
