import {SmartyAddressAutocompleteRequestModel} from '@matchsource/models/smarty-street';

export class GetSmartyAddresses {
  static readonly type = '[SmartyStreetsAutocomplete] Get Smarty Addresses';

  constructor(public readonly request: SmartyAddressAutocompleteRequestModel) {}
}

export class ClearSmartyAddresses {
  static readonly type = '[SmartyStreetsAutocomplete] Clear Smarty Addresses';
}
