import {SmartyAddress} from '@matchsource/models/smarty-street';

export class SmartyValidateAddresses {
  static readonly type = '[SmartyStreetsValidate] Get Smarty Addresses';

  constructor(public readonly addressBase: SmartyAddress) {}
}

export class ClearSmartyValidateAddresses {
  static readonly type = '[SmartyStreetsValidate] Clear Smarty Addresses';
}
